// App.js
import React from 'react';

function App() {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: 'yellow',
      margin: 0,
    },
    text: {
      fontSize: '5em',
      color: 'black',
      fontFamily: 'Arial, sans-serif',
      textAlign: 'center',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.text}>Coming Soon</h1>
    </div>
  );
}

export default App;
